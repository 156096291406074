import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {EasytrackHttpParams} from '../models/easytrack-http-params.model';
import {UserAction} from '../models/user-action.model';
import {User} from '../models/user.model';
import {MessageHandlerService} from './messageHandler.service';
import {PagedResult} from 'bs-components';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private _users$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

  private _currentUser$: BehaviorSubject<User | undefined> =
    new BehaviorSubject<User | undefined>(undefined);

  private readonly serviceUrl: string = environment.baseUrl + '/users';

  constructor(
    private http: HttpClient,
    private messageService: MessageHandlerService
  ) {
  }

  // --------------------------------------------- HTTP FUNCTIONS ---------------------------------------------------------- //

  public getAllUsers(params?: EasytrackHttpParams): Observable<User[]> {
    return this.http
      .get<User[]>(this.serviceUrl, {params: {...params}})
      .pipe(
        tap((users: User[]) => {
          this._users$.next(users);
        }),
        catchError(this.messageService.handleError<User[]>(`Get users`))
      );
  }

  public getOneUser(userId: number): Observable<User> {
    return this.http
      .get<User>(this.serviceUrl + '/' + userId)
      .pipe(catchError(this.messageService.handleError<User>('LOGS.USERS.')));
  }

  public getMe(): Observable<User> {
    return this.http.get(this.serviceUrl + '/me').pipe(
      tap((user: User) => {
        this._currentUser$.next(user);
      }),
      catchError(this.messageService.handleError<User>(`Get me`))
    );
  }

  public createUser(body: CreateOperatorBody): Observable<User> {
    return this.http
      .post<User>(this.serviceUrl, body)
      .pipe(catchError(this.messageService.handleError<User>(`Create user`)));
  }

  public updateUserStatus(
    id: number,
    username: string,
    enabled: boolean
  ): Observable<User> {
    return this.http
      .put<User>(this.serviceUrl + '/' + id + '/status', enabled)
      .pipe(
        tap(() => {
          this.messageService.log(
            'validation',
            `status changed for ${username}`,
            1,
            true
          );
          const users = this._users$.getValue();
          users.forEach((u) => {
            if (u.userId == id) {
              u.active = !enabled;
            }
          });

          this._users$.next(users);
        }),
        catchError(this.messageService.handleError<User>(`Toggle user`))
      );
  }

  public updateUserPassword(
    id: number,
    username: string,
    password: any
  ): Observable<User> {
    const body: any = {
      type: 'password',
      value: password,
      temporary: true,
    };

    return this.http
      .put<User>(this.serviceUrl + '/' + id + '/password', body)
      .pipe(
        tap(() =>
          this.messageService.log(
            'validation',
            `password changed for ${username}`,
            1,
            true
          )
        ),
        catchError(this.messageService.handleError<User>('Reset user password'))
      );
  }

  public getUserActions(
    userId: number,
    params?: EasytrackHttpParams
  ): Observable<PagedResult<UserAction>> {
    return this.http
      .get<PagedResult<UserAction>>(
        `${this.serviceUrl}/${userId}/actions`,
        {params: {...params}}
      )
      .pipe(
        catchError((e) => {
          return of(undefined);
        })
      );
  }
}

export interface CreateOperatorBody {
}
